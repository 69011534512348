import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { authenticate } from '../../services/auth-service';
import { Container, TextField, Button } from '@material-ui/core';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function LoginPage() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [password, setPassword] = useState('');

  const { from } = location.state || { from: { pathname: '/' } };
  const handleSubmit = (e) => {
    e.preventDefault();
    authenticate(password, () => {
      history.replace(from);
    });
  };
  const updateData = e => setPassword(e.target.value);

  return (
    <Container component="main" maxWidth="xs">
      <Card>
        <CardContent>
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={updateData}
                value={password}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit}
              >
                Sign In
              </Button>
            </form>
          </div>
        </CardContent>
      </Card>
    </Container>
  );
}
