import { createAsyncAction, errorResult, successResult, useStoreState } from 'pullstate';
import ItemsStore from '../stores/items-store';

const updateItem = createAsyncAction(
  async ({ apiCall, itemType, slug }) => {
    const result = await apiCall(slug);
    result.itemType = itemType;
    result.slug = slug;
    if (result) {
      return successResult(result);
    }

    return errorResult([], `Couldn't get item: ${result.errorMessage}`);
  },
  {
    postActionHook: ({ result, stores }) => {
      if (!result.error) {
        ItemsStore.update(s => {
          s[result.payload.itemType][result.payload.slug] = result.payload;
        });
      }
    },
    cacheBreakHook: ({ result, timeCached }) => {
      const CACHE_TIME = 5 * 60 * 1000; // 5 min in milliseconds
      return timeCached + CACHE_TIME < Date.now();
    }
  }
);

const updateItems = createAsyncAction(
  async ({ apiCall, itemType }) => {
    const result = await apiCall();
    result.itemType = itemType;
    if (!result.errorMessage) {
      return successResult(result);
    }

    return errorResult([], `Couldn't get items: ${result.errorMessage}`);
  },
  {
    postActionHook: ({ result, stores }) => {
      if (!result.error) {
        ItemsStore.update(s => {
          s[result.payload.itemType] = result.payload;
        });
      }
    },
    cacheBreakHook: ({ result, timeCached }) => {
      const CACHE_TIME = 2 * 60 * 1000; // 2 min in milliseconds
      return timeCached + CACHE_TIME < Date.now();
    }
  }
);

function clearListCache(apiCall, itemType) {
  updateItems.clearCache({ apiCall, itemType });
}

function clearItemCache(apiCall, itemType, slug) {
  updateItem.clearCache({ apiCall, itemType, slug });
}

function useItem(apiCall, itemType, slug) {
  const [finished, result] = updateItem.useBeckon({ apiCall, itemType, slug });
  const item = useStoreState(ItemsStore, s => s[itemType][slug]);

  return [finished, result, item];
}

function useItems(apiCall, itemType) {
  const [finished, result] = updateItems.useBeckon({ apiCall, itemType });
  const items = useStoreState(ItemsStore, s => s[itemType]);

  return [finished, result, items];
}

export { useItems, clearListCache, useItem, clearItemCache };
