import { useCallback } from 'react';
import { useEventListener } from './event-listener';

function useOverrideEnter(callback) {
  const handler = useCallback((e) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      e.preventDefault();
      return callback();
    }
  }, [callback]);
  useEventListener('keydown', handler);
}

export { useOverrideEnter };
