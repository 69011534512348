import definitions from './definitions.json';

function validateUi(uiString) {
  const widgetRegex = /"ui:widget": "(.*?)"/g;
  const errors = [];
  let matches;

  do {
    matches = widgetRegex.exec(uiString);

    if (matches) {
      const usedWidget = matches[1];

      if (definitions.widgets.indexOf(usedWidget) < 0) {
        errors.push(usedWidget);
      }
    }
  } while (matches);

  if (errors.length) {
    throw new Error(`The following widgets aren't supported: ${errors.join(', ')}`);
  }
}

function requiredFieldsCheck(schema, errors = []) {
  if (schema.properties) {
    Object.keys(schema.properties).forEach((key) => {
      requiredFieldsCheck(schema.properties[key], errors);
    });
  }

  if (schema.required && schema.required.length > 0) {
    if (schema.properties) {
      schema.required.forEach((required) => {
        if (!schema.properties[required]) {
          errors.push(required);
        }
      });
    } else {
      errors = errors.concat(schema.required);
    }
  }
  if (errors.length) {
    throw new Error(`The following required fields are missing: ${errors.join(', ')}`);
  }
}

function validateSchema(schemaString) {
  const schema = JSON.parse(schemaString);
  requiredFieldsCheck(schema);
}

export default function validate(schemaString, uiString) {
  validateSchema(schemaString);
  validateUi(uiString);
}
