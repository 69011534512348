import { clearItemCache, clearListCache, useItem, useItems } from './update-items-service';
import api from './api-base';
import { getNameFromSlug } from './slug-service';
import { getTypeDetail } from './types-api';

function createPage(page, folder) {
  const pageData = {
    name: page.name,
    content: {
      _type: page.type
    }
  };
  return api({
    url: `/pages/${folder}`,
    method: 'POST',
    data: pageData
  })
    .then(response => {
      clearItemCache(getPages, 'pages', folder);
      return response.data;
    });
}

function createFolder(folder) {
  return api({
    url: '/folders',
    method: 'POST',
    data: {
      folder: folder.name
    }
  })
    .then(response => {
      clearListCache(getFolders, 'folders');
      return response.data;
    });
}

function getFolders() {
  return api.get('/folders')
    .then(response => response.data.filter((folder) => folder !== '_types' && folder !== 'media')
      .map((folder, index) => {
        const link = {
          value: `/${folder}`
        };
        return { index, name: folder, link };
      })
    );
}

function useFolders() {
  return useItems(getFolders, 'folders');
}

function getPages(folder) {
  return api.get(`/pages/${folder}`)
    .then(response => response.data.list.filter((page) => page.slug.indexOf('.ignore') === -1)
      .map(
        (page, index) => {
          const name = getNameFromSlug(page.slug);
          const status = {
            value: page.published ? 'PUBLISHED' : 'DRAFT',
            cssClass: page.published ? 'success' : 'warning'
          };
          const link = {
            value: `/page/${folder}/${name}`
          };
          return { index, name, link, status, folder };
        }
      )
    );
}

function usePages(folder) {
  return useItem(getPages, 'pages', folder);
}

function getPageDetail(slug) {
  return api.get(`/pages/${slug}`)
    .then(response => {
      const data = response.data;

      if (!data.content._type) {
        return data;
      }

      return getTypeDetail(data.content._type).then((type) => {
        data.type = type;
        return data;
      });
    });
}

function usePageDetail(slug) {
  return useItem(getPageDetail, 'pageDetails', slug);
}

function savePageDetail(slug, page) {
  return api({
    url: `/pages/${slug}`,
    method: 'PUT',
    data: page
  })
    .then(response => {
      clearPageCache(slug);
      return response.data;
    });
}

function publishPage(slug, page) {
  page.status = 'published';
  return savePageDetail(slug, page);
}

function deletePage(slug) {
  return api({
    url: `/pages/${slug}`,
    method: 'DELETE'
  })
    .then(response => {
      const path = slug.split('/');
      clearPageListCache(path[0]);
      return response.data;
    });
}

function deleteFolder(slug) {
  return api({
    url: `/folders/${slug}`,
    method: 'DELETE'
  })
    .then(response => {
      clearFolderListCache();
      return response.data;
    });
}

function clearPageListCache(folder) {
  clearItemCache(getPages, 'pages', folder);
}

function clearFolderListCache() {
  clearListCache(getFolders, 'folders');
}

function clearPageCache(slug) {
  clearItemCache(getPageDetail, 'pageDetails', slug);
}

export {
  createPage,
  getPages,
  usePages,
  usePageDetail,
  getPageDetail,
  savePageDetail,
  publishPage,
  deletePage,
  clearPageCache,
  createFolder,
  getFolders,
  useFolders,
  deleteFolder
};
