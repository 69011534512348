import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import React from 'react';

export default function ViewError({ message }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={true}
    >
      <Alert severity="error" elevation={6} variant="filled">{message}</Alert>
    </Snackbar>
  );
}
