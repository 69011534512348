import { useCallback } from 'react';
import { useEventListener } from './event-listener';

function useOverrideSave(callback) {
  const handler = useCallback((e) => {
    if (e.code === 'KeyS' && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
      e.preventDefault();
      return callback();
    }
  }, [callback]);
  useEventListener('keydown', handler);
}

export default useOverrideSave;
