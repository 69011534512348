import ViewError from './view-error';
import React from 'react';
import StatusStore from '../stores/status-store';


function ViewWrapper({ data, children, viewName, parameters, ignoreAppBarTitle, match }) {
  const appTitle = process.env.REACT_APP_PAGEDATA_TITLE || 'Pagedata';
  const properties = {};
  let error = null;
  let allFinished = true;
  if (data) {
    Object.keys(data).forEach((key) => {
      const [finished, result, items] = data[key](...parameters);
      properties[key] = items;
      if (finished && result.error) {
        error = result.message;
      }
      allFinished = allFinished && finished;
    });
  }

  if (error) {
    return <ViewError message={error} />;
  }

  if (!allFinished) {
    return null;
  }

  if (!ignoreAppBarTitle) {
    StatusStore.update(store => {
      const pageTitle = typeof viewName === 'function' ? viewName(properties) : viewName;
      document.title = `${appTitle} | ${pageTitle}`;
      store.currentPage = pageTitle;
    });
  }

  return (
    <>
      {children({ ...properties })}
    </>
  );
}

ViewWrapper.defaultProps = {
  parameters: []
};

export default ViewWrapper;
