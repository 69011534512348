import React, { useState } from 'react';
import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import commonStyles from './common-styles';
import { Card } from '@material-ui/core';
import './rich-text-widget.css';

const toolbarOptions = {
  options: ['inline', 'blockType', 'list', 'link', 'history'],
  inline: {
    inDropdown: false,
    options: ['bold', 'italic', 'underline']
  },
  blockType: {
    inDropdown: false,
    options: ['Normal', 'H1', 'H2', 'H3'],
  },
  list: {
    inDropdown: false,
    options: ['unordered', 'ordered']
  }
};

function RichTextWidget({ onChange, value, label }) {
  let initialState = null;
  const classes = commonStyles();
  // We need to convert the stored data to an EditorState object and vice-versa
  if (value) {
    const { contentBlocks, entityMap } = convertFromHTML(value);
    const content = ContentState.createFromBlockArray(contentBlocks, entityMap);
    initialState = EditorState.createWithContent(content);
  }
  const [state, setState] = useState(initialState);
  const handleStateChange = (editorState) => {
    setState(editorState);
    onChange(stateToHTML(editorState.getCurrentContent()));
  };

  return (
    <>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <Card>
        <Editor
          editorState={state}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={handleStateChange}
          toolbar={toolbarOptions}
        />
      </Card>
    </>
  );
}

export default RichTextWidget;
