import React from 'react';
import ActionDialog from './action-dialog';
import Button from '@material-ui/core/Button/Button';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

function DeleteElementDialog({ open, element, onClose, returnUrl, deleteAction }) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleDelete = (slug) => {
    deleteAction(slug).then(() => {
      enqueueSnackbar('Element deleted successfully', { variant: 'success' });
      history.push(returnUrl);
      if (onClose) {
        onClose();
      }
    }).catch((error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    });
  };

  const styleProps = {
    fullWidth: true
  };

  const children = (
    <p>
      Are you sure you want to delete "{element}"?
    </p>
  );

  const actions = (
    <>
      <Button onClick={() => onClose()} color="primary">
        Cancel
      </Button>
      <Button onClick={() => handleDelete(element)} color="primary">
        Delete
      </Button>
    </>
  );
  return (
    <ActionDialog title="Delete Element" children={children} actions={actions} onClose={onClose} open={open} styleProps={styleProps}/>
  );
}

DeleteElementDialog.defaultProps = {
  returnUrl: null
};

export default DeleteElementDialog;
