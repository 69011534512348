const blockedFunctionalities = process.env.REACT_APP_PAGEDATA_BLOCK ?
  process.env.REACT_APP_PAGEDATA_BLOCK.split(',') : [];
const CAPABILITIES = {
  newFolder: 'new_folder',
  newPage: 'new_page',
  viewTypes: 'types',
  delete: 'delete',
};

function isAdminMode() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  return params.get('mode') === 'admin';
}

function canAddFolders() {
  return isAdminMode() || !blockedFunctionalities.includes(CAPABILITIES.newFolder);
}

function canViewTypes() {
  return isAdminMode() || !blockedFunctionalities.includes(CAPABILITIES.viewTypes);
}

function canAddPages() {
  return isAdminMode() || !blockedFunctionalities.includes(CAPABILITIES.newPage);
}

function canDelete() {
  return isAdminMode() || !blockedFunctionalities.includes(CAPABILITIES.delete);
}

export { canAddFolders, canViewTypes, canAddPages, canDelete };
