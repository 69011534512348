import TableCell from '@material-ui/core/TableCell/TableCell';
import Button from '@material-ui/core/Button/Button';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';

const icons = {
  EditIcon,
  DeleteIcon
};

export default function LinkCell({ text, value, icon, classes }) {
  if (!icon) {
    return (
      <TableCell component="td">
        <Button component={Link} to={value} color="primary" className={classes}>{text}</Button>
      </TableCell>
    );
  }

  const Icon = icons[icon];

  return (
    <TableCell component="td">
      <Button component={Link} to={value} color="primary" className={classes}><Icon/> {text}</Button>
    </TableCell>
  );
}
