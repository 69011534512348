import SparkMD5 from 'spark-md5';
import CookieMonster from '@firstandthird/cookie-monster';

const validPass = process.env.REACT_APP_PASSWORD_MD5;

function isValid(password) {
  return password === validPass;
}

function isAuthenticated() {
  const password = CookieMonster.get('password');
  return !validPass || isValid(password);
}

function authenticate(password, cb) {
  const hashedPassword = SparkMD5.hash(password);
  if (isValid(hashedPassword)) {
    CookieMonster.set('password', hashedPassword);
  }
  setTimeout(cb, 100); // fake async
}

function signout(cb) {
  CookieMonster.remove('password');
}

export { isAuthenticated, authenticate, signout };
