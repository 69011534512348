import React, { useState } from 'react';
import { Route, withRouter } from 'react-router-dom';
import ItemList from '../../components/item-list';
import TypeFormModal from './type-form-modal';
import DeleteElementDialog from '../../components/delete-element-dialog';
import { deleteType, useTypes } from '../../services/types-api';
import ViewWrapper from '../../components/view-wrapper';

function TypesPage({ match, history, types }) {
  const [selectedType, setSelectedType] = useState(null);
  const handleDeleteClick = name => setSelectedType(name);
  const newTypeUrl = `${match.url}/new`;
  const handleClose = () => history.push(match.url);
  const tableActions = {
    newType: {
      text: 'New type',
      to: newTypeUrl
    }
  };

  const fields = [
    {
      key: 'delete',
      label: '',
      type: 'ActionCell',
      options: {
        text: 'Delete',
        icon: 'DeleteIcon',
        onClick: handleDeleteClick
      }
    }
  ];

  return (
    <div>
      <ItemList items={types} url="/type/" additionalFields={fields} tableActions={tableActions}/>

      <Route path={newTypeUrl} render={() => <TypeFormModal onClose={handleClose} types={types}/>} />

      <DeleteElementDialog
        element={selectedType}
        open={!!selectedType}
        deleteAction={deleteType}
      />
    </div>
  );
}

function Types(props) {
  const dependencies = {
    types: useTypes
  };

  return (
    <ViewWrapper data={dependencies} viewName="Types" match={props.match}>
      {({ types }) => <TypesPage types={types} {...props} />}
    </ViewWrapper>
  );
}

export default withRouter(Types);
