import MarkdownWidget from './markdown-widget';
import RichTextWidget from './rich-text-widget';
import UploadWidget from './upload-widget';

const widgets = {
  markdown: MarkdownWidget,
  richtext: RichTextWidget,
  upload: UploadWidget
};

export default widgets;
