import React from 'react';
import { getPages, createPage } from '../../services/pages-api';
import CreateFormModal from '../../components/create-form-modal';
import { useTypes } from '../../services/types-api';
import ViewWrapper from '../../components/view-wrapper';
import { withRouter } from 'react-router-dom';

function PageFormModal({ onClose, pages, types, folder }) {
  const fields = [
    {
      name: 'type',
      label: 'Select type',
      type: 'select',
      choices: types.map(type => ({ value: type.name, label: type.name })),
      value: '',
      error: null,
      styleProps: {
        fullWidth: true
      }
    },
    {
      name: 'name',
      label: 'Page name',
      type: 'text',
      value: '',
      error: null,
      styleProps: {
        fullWidth: true
      }
    }
  ];

  return (
    <CreateFormModal
      onClose={onClose}
      itemType='pages'
      fields={fields}
      getItems={() => getPages(folder)}
      items={pages}
      title="Create a new Page"
      createItem={createPage}
      folder={folder}
      redirect={`/page/${folder}/`}
    />
  );
}

function PageForm(props) {
  const dependencies = {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    types: useTypes
  };

  return (
    <ViewWrapper data={dependencies} viewName={prop => 'Create a new page'} ignoreAppBarTitle={true}>
      {({ types }) => <PageFormModal types={types} {...props} />}
    </ViewWrapper>
  );
}

export default withRouter(PageForm);
