import React from 'react';
import ActionDialog from './action-dialog';
import Button from '@material-ui/core/Button/Button';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import TextField from '@material-ui/core/TextField/TextField';
import { makeStyles } from '@material-ui/core';
import { useOverrideEnter } from '../hooks/enter-override';

const fieldTypes = {
  text: TextField,
  select: Select
};
const useStyles = makeStyles(theme => ({
  inputMargin: {
    marginTop: theme.spacing(2),
  },
}));

export default function FormModal({
  form,
  formTitle,
  onSubmit,
  onClose,
  updateField
}) {
  useOverrideEnter(() => onSubmit(form));

  const classes = useStyles();
  const formInputs = form.map((row, index) => {
    const id = `${index}-${row.name}`;
    const inputProps = {
      label: row.label,
      key: id,
      name: row.name,
      onChange: e => updateField(e, index),
      error: !!row.error,
      helperText: row.error ? row.error : ''
    };
    const FieldTag = fieldTypes[row.type];

    if (index > 0) {
      row.styleProps.className = classes.inputMargin;
    }

    if (row.type === 'select') {
      const labelId = `${index}-${row.name}-label`;

      return (
        <div key={id}>
          <InputLabel id={labelId}>{row.label}</InputLabel>
          <Select
            labelId={labelId}
            id={id}
            value={row.value}
            onChange={e => updateField(e, index)}
            {...row.styleProps}
          >
            {row.choices.map((choice) => (
              <MenuItem value={choice.value} key={choice.value}>{choice.label}</MenuItem>
            ))}
          </Select>
        </div>
      );
    }

    return <FieldTag {...inputProps} {...row.styleProps} />;
  });

  const styleProps = {
    fullWidth: true
  };

  const actions = (
    <>
      <Button onClick={() => onClose()} color="primary">
        Cancel
      </Button>
      <Button onClick={e => onSubmit(form, e)} color="primary">
        Send
      </Button>
    </>
  );

  return (
    <ActionDialog title={formTitle} actions={actions} onClose={onClose} styleProps={styleProps}>
      <form onSubmit={e => onSubmit(form, e)}>
        {formInputs}
      </form>
    </ActionDialog>
  );
}
