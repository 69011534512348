const axios = require('axios').default;

const endpoint = process.env.REACT_APP_PAGEDATA_ENDPOINT || 'https://v2.pagedata.co';
const token = process.env.REACT_APP_PAGEDATA_TOKEN;

const axiosInstance = axios.create({
  baseURL: `${endpoint}/api`,
  headers: { 'Content-Type': 'application/json', 'X-Api-Token': token }
});

export default axiosInstance;
