import React from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';

function ActionDialog({ title, children, actions, onClose, styleProps, open }) {
  return (
    <Dialog open={open} onClose={onClose} aria-label={title} {...styleProps}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        {actions}
      </DialogActions>
    </Dialog>
  );
}

ActionDialog.defaultProps = {
  open: true
};

export default ActionDialog;
