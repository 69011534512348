import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/xq-light.css';
import Container from '@material-ui/core/Container/Container';
import StatusStore from '../../stores/status-store';
import ViewError from '../../components/view-error';
import { publishPage, savePageDetail, usePageDetail } from '../../services/pages-api';
import { useSnackbar } from 'notistack';
import { withTheme } from 'react-jsonschema-form';
import { Theme as MuiTheme } from 'rjsf-material-ui';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import widgets from '../../components/widgets';
import JsonCodeInput from '../../components/json-code-input';
import ViewWrapper from '../../components/view-wrapper';
import useOverrideSave from '../../hooks/save-override';

const PageForm = forwardRef(({
  page,
  data,
  onSubmit,
  onPublish,
  onChanged,
  publishable
}, ref) => {
  const Form = withTheme(MuiTheme);
  const formRef = useRef();
  const handleValidation = (formData, errors) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    return errors;
  };

  useOverrideSave(() => {
    if (formRef.current) {
      formRef.current.click();
    }
  });

  useImperativeHandle(ref, () => ({
    validateAndSubmit() {
      // Triggering the click is not ideal, but rjsf-material-ui does not provide any mechanisms
      // to trigger submit and validation.
      formRef.current.click();
    }
  }));

  return (<Form
    schema={page.type.content.schema}
    uiSchema={page.type.content.ui}
    formData={data}
    formContext={data}
    onChange={onChanged}
    onSubmit={onSubmit}
    widgets={widgets}
    validate={handleValidation}
  >
    <button type="submit" hidden ref={formRef}/>
  </Form>);
});

const CustomPageForm = forwardRef(({
  data,
  onSubmit,
  onPublish,
  setData,
  onChanged,
  publishable
}, ref) => {
  const [submittable, setSubmittable] = useState(true);
  const [customData, setCustomData] = useState(data ? JSON.stringify(data, null, 2) : null);
  const handleChange = (value) => {
    setCustomData(value);
    let isSubmittable = true;
    try {
      setData(JSON.parse(value));
    } catch (error) {
      isSubmittable = false;
    }
    setSubmittable(isSubmittable);
    onChanged();
    StatusStore.update(s => {
      s.actions.submit.disabled = !isSubmittable;
    });
  };
  useOverrideSave(() => {
    if (submittable) {
      onSubmit();
    }
  });

  useImperativeHandle(ref, () => ({
    validateAndSubmit() {
      if (submittable) {
        onSubmit();
      }
    }
  }));

  return (
    <form>
      <JsonCodeInput value={customData} onBeforeChange={handleChange} />
    </form>
  );
});

export function PageDetailPage({ match }) {
  const slug = match.params.slug;
  const folder = match.params.folder;
  const fullSlug = `${folder}/${slug}`;
  const [data, setData] = useState(null);
  const [publishable, setPublishable] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [finished, result, page] = usePageDetail(fullSlug);
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef();
  let formBody = null;

  const buildPageUpdate = (values) => ({
    name: page.name,
    content: {
      _type: page.content._type,
      data: values
    }
  });

  const handleSubmit = (form) => {
    let submitData;
    if (page.type) {
      submitData = form.formData;
    } else {
      submitData = data;
    }

    savePageDetail(fullSlug, buildPageUpdate(submitData)).then((response) => {
      if (response.status === 'draft') {
        if (page.type) {
          setData(form.formData);
        } else {
          setData(data);
        }
        setPublishable(true);
        enqueueSnackbar('Page draft successfully updated', { variant: 'success' });
      } else {
        enqueueSnackbar('There has been an error', { variant: 'error' });
      }
    });
  };
  const handlePublish = () => {
    publishPage(fullSlug, buildPageUpdate(data)).then((response) => {
      if (response.status === 'published') {
        enqueueSnackbar('Page published successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('There has been an error', { variant: 'error' });
      }
    });
  };
  const handleFormChanged = () => {
    StatusStore.update(s => {
      s.actions.publish.disabled = true;
    });
  };
  StatusStore.update(s => {
    s.actions = {
      submit: {
        callback: () => {
          formRef.current.validateAndSubmit();
        },
        text: 'Submit'
      },
      publish: {
        callback: handlePublish,
        text: 'Publish'
      }
    };
  });

  useEffect(() => {
    if (page) {
      StatusStore.update((s) => {
        s.currentPage = `${page.name}`;
      });
      setLoaded(true);
      if (page.content.data) {
        setData(page.content.data);
      }
    }
  }, [page]);

  if (!finished) {
    return null;
  }

  if (result.error) {
    return <ViewError message={result.message} />;
  }
  if (loaded) {
    if (page.type) {
      formBody = (
        <PageForm
          page={page}
          data={data}
          publishable={publishable}
          onChanged={handleFormChanged}
          onSubmit={handleSubmit}
          onPublish={handlePublish}
          ref={formRef}
        />
      );
    } else {
      formBody = (
        <CustomPageForm
          data={data}
          setData={setData}
          onChanged={handleFormChanged}
          onSubmit={handleSubmit}
          onPublish={handlePublish}
          publishable={publishable}
          ref={formRef}
        />
      );
    }
  }

  return (
    <Container>
      <Card>
        <CardContent>
          {formBody}
        </CardContent>
      </Card>
    </Container>
  );
}

function PageDetail(props) {
  return (
    <ViewWrapper viewName={prop => props.match.params.slug}>
      {({ types }) => <PageDetailPage{...props} />}
    </ViewWrapper>
  );
}

export default withRouter(PageDetail);
