import React, { useState } from 'react';
import { Route, withRouter } from 'react-router-dom';
import ItemList from '../../components/item-list';
import { deleteFolder, useFolders } from '../../services/pages-api';
import ViewWrapper from '../../components/view-wrapper';
import DeleteElementDialog from '../../components/delete-element-dialog';
import FolderFormModal from './folder-form-modal';
import { canAddFolders, canDelete, canViewTypes } from '../../services/capabilities-service';

export function HomePage({ match, history, folders }) {
  const [selectedFolder, setSelectedFolder] = useState(null);
  const newFolderUrl = `${match.url}/new`;
  const handleClose = () => history.push(match.url);
  const handleDeleteClick = name => setSelectedFolder(`${name}`);
  const fields = [];
  const tableActions = {};

  if (canDelete()) {
    fields.push({
      key: 'delete',
      label: '',
      type: 'ActionCell',
      options: {
        text: 'Delete',
        icon: 'DeleteIcon',
        onClick: handleDeleteClick
      }
    });
  }

  if (canViewTypes()) {
    tableActions.types = {
      text: 'Types',
      to: '/types'
    };
  }

  if (canAddFolders()) {
    tableActions.newFolder = {
      text: 'New folder',
      to: newFolderUrl
    };
  }

  return (
    <div>
      <ItemList items={folders} additionalFields={fields} url="/pages/" tableActions={tableActions} />

      <DeleteElementDialog
        element={selectedFolder}
        open={!!selectedFolder}
        deleteAction={deleteFolder}
      />
      <Route path={newFolderUrl} render={() => <FolderFormModal onClose={handleClose} folders={folders} />} />
    </div>
  );
}

function Home(render) {
  const dependencies = {
    folders: useFolders
  };
  return (
    <ViewWrapper data={dependencies} viewName="Home" match={render.match}>
      {({ folders }) => <HomePage folders={folders} {...render} />}
    </ViewWrapper>
  );
}

export default withRouter(Home);
