import makeStyles from '@material-ui/core/styles/makeStyles';
import TableCell from '@material-ui/core/TableCell/TableCell';
import React from 'react';

const useStyles = makeStyles(theme => ({
  warning: {
    color: theme.palette.warning.dark
  },
  success: {
    color: theme.palette.success.dark
  }
}));

export default function StyledCell({ cssClass, value }) {
  const classes = useStyles();

  return (
    <TableCell component="td" className={classes[cssClass]}>
      {value}
    </TableCell>
  );
}
