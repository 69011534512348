import React, { useState, useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import axiosInstance from '../services/api-base';

export default function GlobalProgressBar() {
  const [pendingRequests, setPendingRequests] = useState(0);
  let progress = null;

  if (pendingRequests) {
    progress = <LinearProgress color="secondary" />;
  }

  useEffect(() => {
    axiosInstance.interceptors.request.use(config => {
      setPendingRequests(requests => requests + 1);
      return config;
    });

    axiosInstance.interceptors.response.use(response => {
      setPendingRequests(requests => requests - 1);
      return response;
    }, error => {
      setPendingRequests(requests => requests - 1);
      return Promise.reject(error);
    });
  }, []);

  return (<div className="global-progress-bar">{progress}</div>);
}
