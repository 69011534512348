import TableCell from '@material-ui/core/TableCell/TableCell';
import Button from '@material-ui/core/Button/Button';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';

const icons = {
  EditIcon,
  DeleteIcon
};

export default function ActionCell({ text, icon, onClick, identifier }) {
  const Icon = icons[icon];
  const handleClick = () => onClick(identifier);

  return (
    <TableCell component="td">
      <Button onClick={handleClick} color="primary"><Icon/> {text}</Button>
    </TableCell>
  );
}
