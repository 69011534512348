import React from 'react';
import { getTypes, createType } from '../../services/types-api';
import CreateFormModal from '../../components/create-form-modal';

export default function TypeFormModal({ onClose, types }) {
  const fields = [{
    name: 'name',
    label: 'Type name',
    value: '',
    type: 'text',
    error: null,
    styleProps: {
      fullWidth: true
    }
  }];

  return (
    <CreateFormModal
      onClose={onClose}
      itemType='types'
      fields={fields}
      getItems={getTypes}
      items={types}
      title="Create a new Type"
      createItem={createType}
      redirect="/type/"
    />
  );
}
