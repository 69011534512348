import { Controlled as CodeMirror } from 'react-codemirror2';
import React from 'react';
import 'codemirror/lib/codemirror.css';
import './markdown-widget.css';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import commonStyles from './common-styles';
import { Card } from '@material-ui/core';
require('codemirror/mode/markdown/markdown');

function MarkdownWidget({ onChange, value, label, required, readonly, autofocus }) {
  const classes = commonStyles();
  if (required) {
    label += ' *';
  }

  return (<>
    <InputLabel className={classes.label}>{label}</InputLabel>
    <Card>
      <CodeMirror
        value={value}
        options={{
          theme: 'pagedata',
          mode: 'markdown',
          lineNumbers: true,
          readOnly: readonly,
          autofocus
        }}
        onBeforeChange={(editor, data, val) => onChange(val)}
      />
    </Card>
  </>);
}

export default MarkdownWidget;
