import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { clearTypeCache, editTypeDetail, useTypeDetail } from '../../services/types-api';
import CardContent from '@material-ui/core/CardContent/CardContent';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card/Card';
import CardHeader from '@material-ui/core/CardHeader/CardHeader';
import Grid from '@material-ui/core/Grid/Grid';
import { useSnackbar } from 'notistack';
import { withTheme } from 'react-jsonschema-form';
import { Theme as MuiTheme } from 'rjsf-material-ui';
import ViewWrapper from '../../components/view-wrapper';
import JsonCodeInput from '../../components/json-code-input';
import widgets from '../../components/widgets';
import validateWidgets from '../../components/widgets/validate';
import StatusStore from '../../stores/status-store';
import useOverrideSave from '../../hooks/save-override';

const useStyles = makeStyles(theme => ({
  card: {
    textAlign: 'left',
    margin: '16px'
  },
  header: {
    borderBottom: '1px solid #999',
    backgroundColor: theme.palette.primary.light,
    color: '#fff'
  },
  nopadding: {
    padding: '0 !important'
  },
  alignRight: {
    textAlign: 'right'
  },
  hMargin: {
    margin: '0 8px'
  }
}));

function FormPreview({ ui, schema }) {
  const Form = withTheme(MuiTheme);

  // Getting rid of autofocus on Preview Mode to avoid constant focus switch
  const cleanedUI = ui ? ui.replace(/"ui:autofocus": true(,?)/g, '') : '';
  let error = null;
  let uiObject;
  let schemaObject;
  let markup;

  try {
    uiObject = JSON.parse(cleanedUI);
  } catch (e) {
    error = 'UI Schema is not valid';
  }

  try {
    schemaObject = JSON.parse(schema);
  } catch (e) {
    error = 'Schema is not valid';
  }

  if (!error) {
    try {
      validateWidgets(schema, cleanedUI);
    } catch (e) {
      error = e.message;
    }
  }

  if (error) {
    markup = (<p>{error}</p>);
  } else {
    markup = (<Form
      schema={schemaObject}
      uiSchema={uiObject}
      children={<br/>}
      widgets={widgets}
    />);
  }

  return markup;
}

export function TypeDetailPage({ type, slug }) {
  const classes = useStyles();
  const [schema, setSchema] = React.useState('');
  const [ui, setUi] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();


  useEffect(() => {
    if (type && type.content) {
      setSchema(JSON.stringify(type.content.schema, null, 2));
      setUi(JSON.stringify(type.content.ui, null, 2));
    }
  }, [type]);

  const handleSendClick = () => {
    const content = {};

    try {
      if (schema) {
        content.schema = JSON.parse(schema);
      }

      if (ui) {
        content.ui = JSON.parse(ui);
      }

      editTypeDetail(slug, { name: type.name, content }).then((res) => {
        if (res.msg) {
          enqueueSnackbar(res.msg, { variant: 'error' });
        } else {
          enqueueSnackbar('Type successfully updated', { variant: 'success' });
          clearTypeCache(slug);
        }
      });
    } catch (exception) {
      enqueueSnackbar('Please, check the code format', { variant: 'error' });
    }
  };
  StatusStore.update(s => {
    s.actions = {
      save: {
        callback: handleSendClick,
        text: 'Save'
      }
    };
  });

  useOverrideSave(handleSendClick);

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          You can find the Schema and UI Schema documentation <a href="https://react-jsonschema-form.readthedocs.io/en/latest/form-customization/">here</a>.
          You can also see some examples in the <a href="https://rjsf-team.github.io/react-jsonschema-form/">playground</a>.
        </CardContent>
      </Card>
      <Grid container direction="row">
        <Grid item xs={6}>
          <Card className={classes.card}>
            <CardHeader title="Schema" className={classes.header}/>

            <CardContent className={classes.nopadding}>
              <JsonCodeInput value={schema} onBeforeChange={setSchema} />
            </CardContent>
          </Card>

          <Card className={classes.card}>
            <CardHeader title="UI" className={classes.header}/>

            <CardContent className={classes.nopadding}>
              <JsonCodeInput value={ui} onBeforeChange={setUi} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card className={classes.card}>
            <CardHeader title="Form Preview" className={classes.header}/>

            <CardContent>
              <FormPreview ui={ui} schema={schema}/>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}


function TypeDetail(props) {
  const slug = props.match.params.slug;
  const dependencies = {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    type: () => useTypeDetail(slug)
  };

  return (
    <ViewWrapper
      data={dependencies}
      viewName={prop => (prop.type ? `${prop.type.name}` : 'Type Detail')}
      match={props.match}>
      {({ type }) => <TypeDetailPage type={type} slug={slug} {...props} />}
    </ViewWrapper>
  );
}

export default withRouter(TypeDetail);
