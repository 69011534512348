import React from 'react';
import { clearListCache } from '../services/update-items-service';
import FormModal from './form-modal';
import { useHistory } from 'react-router-dom';

export default function CreateFormModal({ onClose, items, createItem, getItems, itemType, fields, title, redirect, folder }) {
  const history = useHistory();
  const [itemData, setItemData] = React.useState(fields);

  const handleFormSubmit = async (formFields) => {
    const newItem = itemData.slice(0);
    const itemPost = {
      content: {}
    };
    let match = false;

    formFields.forEach(field => {
      if (field.name === 'name') {
        const itemName = field.value;
        match = items.find((item) => item.name.toLowerCase() === itemName.toLowerCase());

        if (match) {
          newItem[0].error = 'An item with this name already exists.';
          setItemData(newItem);
        }
      }

      itemPost[field.name] = field.value;
    });

    if (!match) {
      const itemInfo = await createItem(itemPost, folder);

      if (itemInfo.key && itemInfo.slug && redirect) {
        history.push(redirect + itemInfo.slug);
        clearListCache(getItems, itemType);
      } else if (redirect && itemInfo.key.indexOf('.ignore') > -1) {
        const slug = itemInfo.key.replace('.ignore', '');
        history.push(redirect + slug);
      } else if (redirect) {
        history.push(redirect);
      }
    }
  };

  const updateField = (e, index) => {
    const formFields = itemData.slice(0);
    formFields[index].value = e.target.value;
    setItemData(formFields);
  };

  return (
    <>
      <FormModal
        formTitle={title}
        form={itemData}
        onSubmit={handleFormSubmit}
        onClose={onClose}
        updateField={updateField}
      />
    </>
  );
}
