import React from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/xq-light.css';
require('codemirror/mode/javascript/javascript');

export default function JsonCodeInput({ value, onBeforeChange }) {
  return <CodeMirror
    value={value}
    options={{
      theme: 'xq-light',
      mode: { name: 'javascript', json: true },
      lineNumbers: true
    }}
    onBeforeChange={(editor, data, val) => onBeforeChange(val)}
  />;
}
