import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Route } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Toolbar } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import AppBar from '@material-ui/core/AppBar/AppBar';
import GlobalProgressBar from './global-progress-bar';
import ActionsToolbar from './actions-toolbar';
import Box from '@material-ui/core/Box';

const breadcrumbNameMap = {
  '/^': 'Home',
  '/folders^': 'Home',
  '/types^': 'Types',
  '/type': 'Types',
  '/pages/[a-zA-Z]+': ':slug',
  '/page/[a-zA-Z]+': ':slug',
  '/type/[a-zA-Z]+': ':slug'
};

function getBreadcrumbName(path) {
  if (breadcrumbNameMap[path]) {
    return breadcrumbNameMap[path];
  }
  let pageName = '';
  Object.keys(breadcrumbNameMap)
    .filter(key => (new RegExp(key)).test(path))
    .forEach(key => { pageName = breadcrumbNameMap[key]; });
  const pathParts = path.split('/');
  const slug = pathParts[pathParts.length - 1];
  pageName = pageName.replace(':slug', slug);
  return pageName;
}

function ListItemLink(props) {
  const { to, ...other } = props;

  return (
    <Link component={RouterLink} to={to} {...other}>
      {props.children}
    </Link>
  );
}

ListItemLink.propTypes = {
  open: PropTypes.bool,
  to: PropTypes.string.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  lists: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1)
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  rightActions: {
    textAlign: 'right'
  },
  breadcrumbs: {
    padding: '6px 0'
  }
}));

const LinkRouter = props => <Link {...props} component={RouterLink} />;

export default function AppToolbar() {
  const classes = useStyles();
  const title = process.env.REACT_APP_PAGEDATA_TITLE || 'Pagedata';
  document.title = title;
  return (
    <div className={classes.root}>
      <Route>
        {({ location }) => {
          const pathnames = location.pathname.split('/').filter(x => x);
          const breadcrumbs = pathnames.map((value, index) => {
            const last = index === pathnames.length - 1;
            let to = `/${pathnames.slice(0, index + 1).join('/')}`;
            const parent = index > 0 ? pathnames[index - 1] : null;
            if (index === 1 && parent === 'page') {
              const splitPath = pathnames.slice(0, index + 1);
              splitPath[0] += 's';
              to = `/${splitPath.join('/')}`;
            }

            const name = getBreadcrumbName(to);
            if (name === 'Home' || name === '') {
              return null;
            }
            return last ? (
              <Typography color="inherit" variant="h6" key={to}>
                {name}
              </Typography>
            ) : (
              <LinkRouter color="inherit" to={to} key={to}>
                <Typography variant="h6">
                  {name}
                </Typography>
              </LinkRouter>
            );
          });

          return (
            <>
              <AppBar position="fixed">
                <Container>
                  <Toolbar disableGutters={true}>
                    <Grid
                      container
                      direction="row"
                    >
                      <Grid item xs={6}>
                        <Breadcrumbs color="inherit" aria-label="breadcrumb" className={classes.breadcrumbs}>
                          <LinkRouter color="inherit" to="/">
                            <Typography variant="h6">
                              {title}
                            </Typography>
                          </LinkRouter>
                          {breadcrumbs}
                        </Breadcrumbs>
                      </Grid>
                      <Grid item xs={6} className={classes.rightActions}>
                        <ActionsToolbar/>
                      </Grid>
                    </Grid>
                  </Toolbar>
                </Container>
              </AppBar>
              <Toolbar/>
              <Box mb={2}>
                <GlobalProgressBar />
              </Box>
            </>
          );
        }}
      </Route>
    </div>
  );
}
