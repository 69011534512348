import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button/Button';
import { useStoreState } from 'pullstate';
import StatusStore from '../stores/status-store';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  rightActionButton: {
    display: 'inline-block'
  }
}));

export default function ActionsToolbar() {
  const classes = useStyles();
  const actions = useStoreState(StatusStore).actions;
  const sortedActions = Object.values(actions).sort((a, b) => a.position - b.position);
  return (
    <>
      {sortedActions.map((action, i) => (
        action.to ?
          <Box ml={1} className={classes.rightActionButton} key={i}>
            <Button variant="contained" component={Link} href={`#${action.to}`}
              disabled={action.disabled}>
              {action.text}
            </Button>
          </Box>
          :
          <Box ml={1} className={classes.rightActionButton} key={i}>
            <Button variant="contained" component={Link} onClick={action.callback}
              disabled={action.disabled}>
              {action.text}
            </Button>
          </Box>
      ))}
    </>
  );
}
