import React from 'react';
import { getFolders, createFolder } from '../../services/pages-api';
import CreateFormModal from '../../components/create-form-modal';
import { withRouter } from 'react-router-dom';

function FolderFormModal({ onClose, folders }) {
  const fields = [
    {
      name: 'name',
      label: 'Folder name',
      type: 'text',
      value: '',
      error: null,
      styleProps: {
        fullWidth: true
      }
    }
  ];

  return (
    <CreateFormModal
      onClose={onClose}
      itemType="folders"
      fields={fields}
      getItems={() => getFolders()}
      items={folders}
      title="Create a new Folder"
      createItem={createFolder}
      redirect="/folders/"
    />
  );
}

export default withRouter(FolderFormModal);
