import React, { useEffect, useState } from 'react';
import HomePage from './modules/home/home-page';
import TypesPage from './modules/types/types-page';
import { Route, HashRouter as Router, Redirect } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import TypeDetailPage from './modules/types/type-detail-page';
import { SnackbarProvider } from 'notistack';
import PageDetailPage from './modules/pages/page-detail-page';
import CssBaseline from '@material-ui/core/CssBaseline';
import FolderPagesPage from './modules/pages/folder-pages-page';
import AppToolbar from './components/app-toolbar';
import LoginPage from './modules/auth/login-page';
import { isAuthenticated } from './services/auth-service';

const pagedataStyles = createMuiTheme({
  palette: {
    primary: {
      light: '#4b81a0',
      main: '#155571',
      dark: '#002c46',
      contrastText: '#fff'
    },
    secondary: {
      light: '#ff9f7c',
      main: '#e36f4f',
      dark: '#ac4025',
      contrastText: '#fff',
    },
    background: {
      default: '#f5f5f5'
    }
  },
  props: {
    MuiTextField: {
      variant: 'outlined'
    }
  }
});

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => (
        isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      )}
    />
  );
}

function App() {
  const [globalWait, setGlobalWait] = useState(false);

  useEffect(() => setGlobalWait(true), []);
  let routerCode = null;

  if (globalWait) {
    routerCode = (
      <Router initialEntries={['/']} initialIndex={0}>
        <Route path="/">
          <AppToolbar/>
        </Route>
        <PrivateRoute exact path="/">
          <Redirect from="/" to="/folders" />
        </PrivateRoute>
        <PrivateRoute path="/folders">
          <HomePage/>
        </PrivateRoute>
        <PrivateRoute exact path="/pages">
          <Redirect from="/pages" to="/folders" />
        </PrivateRoute>
        <PrivateRoute path="/pages/:slug">
          <FolderPagesPage/>
        </PrivateRoute>
        <PrivateRoute path="/types">
          <TypesPage/>
        </PrivateRoute>
        <PrivateRoute exact path="/type">
          <Redirect from="/type" to="/types" />
        </PrivateRoute>
        <PrivateRoute path="/type/:slug">
          <TypeDetailPage/>
        </PrivateRoute>
        <PrivateRoute path="/page/:folder/:slug">
          <PageDetailPage/>
        </PrivateRoute>
        <Route path="/login" exact>
          <LoginPage />
        </Route>
      </Router>
    );
  }

  return (
    <SnackbarProvider maxSnack={3}>
      <ThemeProvider theme={pagedataStyles}>
        <CssBaseline />
        <div className="pagedata">
          {routerCode}
        </div>
      </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;
