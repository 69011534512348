import { Store } from 'pullstate';

const ItemsStore = new Store({
  folders: [],
  pages: {},
  types: [],
  typeDetails: {},
  pageDetails: {}
});

export default ItemsStore;
