import React, { useRef } from 'react';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import { useEventListener } from '../../hooks/event-listener';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Delete } from '@material-ui/icons';
import Box from '@material-ui/core/Box';

const widgetStyles = makeStyles(theme => ({
  iframe: {
    height: 150,
    width: '100%',
    border: 'none'
  },
  errorDiv: {
    height: 100,
    width: '100%',
    textAlign: 'center',
    paddingTop: 35,
    border: '1px solid rgba(0, 0, 0, 0.42)'
  }
}));

const fileTypes = {
  png: 'image',
  jpg: 'image',
  jpeg: 'image',
  gif: 'image',
  bpm: 'image',
  webp: 'image',
  svg: 'image',
  mp4: 'video',
  webm: 'video',
  ogg: 'video'
};

function getTypeFromExtension(url) {
  const urlParts = url.split('.');
  if (urlParts.length > 1) {
    const extension = urlParts[urlParts.length - 1];
    return fileTypes[extension];
  }
  return null;
}

function ImagePreview({ value, onChange }) {
  const useStyles = makeStyles(theme => ({
    previewContainer: {
      height: 150,
      position: 'relative',
      border: '1px solid rgba(0, 0, 0, 0.42)',
      textAlign: 'center'
    },
    preview: {
      height: 150,
      width: 'auto'
    },
    deleteButton: {
      position: 'absolute',
      right: 5,
      top: 5
    }
  }));
  const classes = useStyles();
  const handleClick = () => onChange(null);
  const type = getTypeFromExtension(value);
  let preview = '';

  switch (type) {
    case 'image':
      preview = <img src={value} className={classes.preview} alt="" />;
      break;
    case 'video':
      preview = <video src={value} className={classes.preview} controls />;
      break;
    default:
      preview = value;
  }

  return (
    <div className={classes.previewContainer}>
      {preview}
      <Button color="primary" className={classes.deleteButton} onClick={handleClick}>
        <Delete/>
      </Button>
    </div>
  );
}

function UploadWidget({ onChange, value, label }) {
  const iframe = useRef(null);
  const classes = widgetStyles();
  const endpoint = process.env.REACT_APP_PAGEDATA_UPLOAD_WIDGET;
  let frame = <iframe ref={iframe} src={endpoint} className={classes.iframe} title={label} />;
  if (!endpoint) {
    frame = <div className={classes.errorDiv}>The upload widget has not been defined.</div>;
  } else if (value) {
    frame = <ImagePreview value={value} onChange={onChange} />;
  }
  useEventListener('message', (event) => {
    let uploadData;
    try {
      uploadData = JSON.parse(event.data);
    } catch (e) {
      uploadData = {};
    }
    if (uploadData.type === 'complete' && event.source && iframe.current
      && event.source.window === iframe.current.contentWindow) {
      onChange(uploadData.data.location);
    }
  });

  return (
    <>
      <InputLabel>{label}</InputLabel>
      <Box my={1}>
        {frame}
      </Box>
    </>
  );
}

export default UploadWidget;
