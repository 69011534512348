import { clearItemCache, clearListCache, useItem, useItems } from './update-items-service';
import api from './api-base';
import { getNameFromSlug } from './slug-service';
import { clearPageCache } from './pages-api';
import ItemsStore from '../stores/items-store';

function createType(type) {
  return api({
    url: '/pages/_types',
    method: 'POST',
    data: type
  })
    .then(response => response.data);
}

function getTypes() {
  return api.get('/pages/_types')
    .then(response => response.data.list.map((type, index) => {
      const name = getNameFromSlug(type.slug);
      const link = {
        value: `/type/${name}`
      };

      return { index, name, link };
    }));
}

function useTypes() {
  return useItems(getTypes, 'types', '/type/');
}

function getTypeDetail(name) {
  const slug = encodeURI(name);
  return api.get(`/pages/_types/${slug}`)
    .then(response => response.data);
}

function useTypeDetail(slug) {
  return useItem(getTypeDetail, 'typeDetails', slug);
}

function editTypeDetail(slug, type) {
  return api({
    url: `/pages/_types/${slug}`,
    method: 'PUT',
    data: type
  })
    .then(response => {
      clearTypeCache(slug);
      return response.data;
    });
}

function deleteType(slug) {
  return api({
    url: `/pages/_types/${slug}`,
    method: 'DELETE'
  })
    .then(response => {
      clearTypeListCache();
      return response.data;
    });
}

function clearTypeListCache() {
  clearListCache(getTypes, 'types');
}

function clearTypeCache(slug) {
  clearItemCache(getTypeDetail, 'typeDetails', slug);
  const pages = ItemsStore.getRawState().pageDetails;
  const typePages = Object.keys(pages).filter((pageSlug) => pages[pageSlug].content._type === slug);
  typePages.map((pageSlug) => clearPageCache(pageSlug));
}

export {
  createType,
  getTypes,
  useTypes,
  getTypeDetail,
  editTypeDetail,
  useTypeDetail,
  clearTypeListCache,
  clearTypeCache,
  deleteType
};
