import React, { useState } from 'react';
import { Route, withRouter } from 'react-router-dom';
import ItemList from '../../components/item-list';
import { usePages, deletePage } from '../../services/pages-api';
import PageFormModal from '../pages/page-form-modal';
import ViewWrapper from '../../components/view-wrapper';
import DeleteElementDialog from '../../components/delete-element-dialog';
import { canAddPages, canDelete } from '../../services/capabilities-service';

export function FolderPagesPage({ match, history, pages, folder }) {
  const [selectedPage, setSelectedPage] = useState(null);
  const newPageUrl = `/pages/${folder}/new`;
  const handleClose = () => history.push(match.url);
  const handleDeleteClick = name => setSelectedPage(`${folder}/${name}`);
  const fields = [
    {
      key: 'status',
      label: 'Status',
      type: 'StyledCell'
    },
  ];
  const tableActions = {};

  if (canDelete()) {
    fields.push({
      key: 'delete',
      label: '',
      type: 'ActionCell',
      options: {
        text: 'Delete',
        icon: 'DeleteIcon',
        onClick: handleDeleteClick
      }
    });
  }

  if (canAddPages()) {
    tableActions.newPage = {
      text: 'New page',
      to: newPageUrl
    };
  }

  return (
    <div>
      <ItemList items={pages} additionalFields={fields} url={`/page/${folder}/`} tableActions={tableActions} />

      <DeleteElementDialog
        element={selectedPage}
        open={!!selectedPage}
        deleteAction={deletePage}
      />
      <Route path={newPageUrl} render={() => <PageFormModal onClose={handleClose} pages={pages} folder={folder} />} />
    </div>
  );
}

function FolderPages(props) {
  const folder = props.match.params.slug;
  const dependencies = {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    pages: () => usePages(folder)
  };
  return (
    <ViewWrapper data={dependencies} viewName={folder} match={props.match}>
      {({ pages }) => <FolderPagesPage pages={pages} folder={folder} {...props} />}
    </ViewWrapper>
  );
}

export default withRouter(FolderPages);
