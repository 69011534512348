import React from 'react';
import TableContainer from '@material-ui/core/TableContainer/TableContainer';
import Table from '@material-ui/core/Table/Table';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card/Card';
import StyledCell from './cell-types/styled-cell';
import LinkCell from './cell-types/link-cell';
import ActionCell from './cell-types/action-cell';
import Container from '@material-ui/core/Container';
import StatusStore from '../stores/status-store';

const useStyles = makeStyles(() => ({
  card: {
    textAlign: 'left',
    margin: '0 0 16px'
  },
  mainLink: {
    textTransform: 'none',
    minWidth: 'unset'
  }
}));

const fieldTypes = {
  LinkCell,
  StyledCell,
  ActionCell
};

function ItemName({ children, url }) {
  const classes = useStyles();

  if (url) {
    return (
      <LinkCell text={children} value={url + children} classes={classes.mainLink} />
    );
  }
  return (
    <TableCell component="td" scope="row">
      {children}
    </TableCell>
  );
}

function ItemList({ items, additionalFields, idField, tableActions, url }) {
  const classes = useStyles();

  StatusStore.update(s => {
    s.actions = tableActions;
  });

  return (
    <Container>
      <TableContainer component={Card} className={classes.card}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell component="th">Name</TableCell>
              {additionalFields.map(field => <TableCell key={`${field.key}-header`} component="th">{field.label}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(row =>
              (
                <TableRow hover key={row.index}>
                  <ItemName url={url}>{row.name}</ItemName>
                  {additionalFields.map((field) => {
                    const Type = fieldTypes[field.type];
                    return <Type component="td" key={row.index + field.key} identifier={row[idField]} {...field.options} {...row[field.key]}/>;
                  })}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

ItemList.defaultProps = {
  additionalFields: [],
  idField: 'name'
};

export default ItemList;
